<template>
    <section class="direction text-center" id="direction">
        <v-img class="d-sm-none stripe" width="100%" src="~@/assets/home/SHU-runner2.jpg"/>
        <!--<set-filter v-if="isFilterOpen" class="d-sm-none"/>-->
        <div class="direction__head">
            <v-img :src="item.image" alt="" class="direction__img d-sm-none"/>
            <v-img :src="item.imageLg" alt="" class="direction__img d-none d-sm-block"/>

            <h1 :class="{'direction__title--reversed':isReversed==='1'}"
                class="direction__title">
                {{ item.name }}
            </h1>
        </div>
        <v-container>

            <div class="d-flex flex-wrap justify-center mt-sm-16">
                <div v-for="(product, index) of products" :key="index" >
                    <product :item="product"/>
                </div>
            </div>


        </v-container>
        <insta-shu v-if="languageCode !== 'en'"/>
        <follow/>
    </section>

</template>

<style lang="scss">
.direction {

    & .container {
        @include up($sm) {
            padding: 0 48px;
        }
        @include up($md) {
            padding: 0 88px;
        }
    }

    & .product__color {
        width: 31px;
        height: 31px;
    }

    & .insta-shu {
        margin-top: 150px;
    }

    & .follow {
        margin-top: 130px;
        margin-bottom: 50px;
    }

    & h1 {
        margin-top: 0;
    }

    &__head {
        //display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @include up($sm) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;
        }
    }

    &__title {
        color: $black;
        text-shadow: 0px 0px 20px rgba(169, 11, 29, 0.25);
        padding: 100px 30px 45px;
        text-align: left;
        position: relative;
        @include up($sm) {
            max-width: 574px;
            position: absolute;
            left: 50%;
            margin-right: -50%;
            padding: 150px 30px 45px;
        }

        &--reversed {
            @include up($sm) {
                right: 50%;
                margin-left: -50%;
                left: unset;
                margin-right: unset;
                max-width: 574px;
                position: absolute;
                padding: 150px 30px 45px;
            }
        }

        &:before {
            position: absolute;
            content: "";
            width: 53px;
            height: 68px;
            top: 18px;
            background-image: url("~@/assets/anchor.png");
            @include up($sm) {
                top: 50px;
            }
        }
    }

    &__img {
        width: 100%;
        object-fit: cover;
        height: 500px;
        @include up($sm) {
            height: 700px;
        }
    }

    &__filter {
        display: flex;
        flex-direction: column;
        align-items: center;

        .checkbox-select-custom {
            width: 200px;

            &:last-child {
                margin-top: 20px;
            }
        }

        @include up($sm) {
            flex-direction: row;
            align-items: baseline;
            justify-content: center;

            .checkbox-select-custom {
                width: 240px;
                margin: 20px 10px 0 10px;
            }
        }
    }

    &__select {
        @include up($sm) {
            width: unset;
        }
    }

    & .select {
        position: relative;

        &-active {
            border-radius: unset;
            border-top-right-radius: 24px;
            border-top-left-radius: 24px;
        }

        & ul {
            position: absolute;
            top: 30px;
            left: 0;
            z-index: 2;
            width: 100%;
            border-bottom-right-radius: 24px;
            border-bottom-left-radius: 24px;
            background-color: $additional100;
            padding: 13px 30px 13px 17px;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.06);
        }
    }

    &__wrapper {
        display: flex;
        align-items: baseline;
        margin: auto;
    }

    &__sort {
        font-weight: 300;
        font-size: 18px;
    }
}
</style>

<script>
import Directions from "./directions";
import MainBanner from "./main-banner";
import Product from "../components/product";
import SwitchButton from "../components/switch-button";
import SetFilter from "../components/set-filter";
import FilterCheckboxes from "../components/filter-checkboxes";
import CheckboxSelectCustom from "../components/checkbox-select-custom";
import SelectCustom from "../components/select-custom";
import InstaShu from "./insta-shu";
import Follow from "./follow";


export default {
    name: 'Direction',
    components: {
        Follow,
        InstaShu,
        SelectCustom, CheckboxSelectCustom,
        FilterCheckboxes, SetFilter, SwitchButton, Product, Directions, MainBanner
    },
    props: {
        dir: {
            default: null,
            type: String
        },
    },
    data() {
        return {
            isFilterOpen: false,
            activeSelect: false,
            filterID: null
        }
    },
    computed: {
        languageCode() {
            return this.$store.state.common.languageCode;
        },
        secondBanner() {
            if (!this.$store.state.page?.ProductListController?.banners) return null;
            if (this.$store.state.page?.ProductListController?.banners.length < 2) return null;
            return this.$store.state.page?.ProductListController?.banners[1];
        },
        products() {
            return this.$store.state.page?.ProductListController?.items;
        },
        item() {
            return {
                name: this.$store.state.page?.ProductListController?.section?.UF_NAME,
                imageLg: this.$store.state.page?.ProductListController?.section?.DETAIL_PICTURE,
                image: this.$store.state.page?.ProductListController?.section?.UF_DIRECTION_PIC
            };
        },
        texts() {
            return this.$store.state.page?.ProductListController?.texts;
        },

        isReversed() {
            return this.$store.state.page?.ProductListController?.section?.UF_REVERSED
        }
    },

    methods: {
        toggleSetFilter() {
            this.isFilterOpen = !this.isFilterOpen
        },

        onFilterChange() {
            this.filterID = null;
        }
    }
}


</script>